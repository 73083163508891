$spb-yellow: #fedd46;
$spb-green: #bbdfd0;
$spb-whitesmoke: #d3d3d3;
$spb-grey: #f3f2f1;
$spb-pink: #edd7cc;
$spb-blue: #d7e9f5;
$spb-darker-blue: #7c9ed3;
$spb-red: #be0005;
$spb-dark-green: #006400;
$spb-black: #000;
$spb-white: #fff;
$spb-charcoal-grey: #333;
$spb-small: 766px;
$spb-medium: 996px;
$spb-max-width: 1170px;
